
import { defineComponent } from "vue";
export default defineComponent({
  name: "MyTestimonial",
  props: {
    name: { type: String, default: "Name" },
    country: {
      type: String,
      default: "Portugal",
    },
    description: { type: String, default: "Description" },
    image: {
      type: String,
      default: "franzi",
    },
  },
  methods: {
    getImgUrl(image: string) {
      var images = require.context("@/assets/people/", false, /\.jpg$/);
      return images("./" + image + ".jpg");
    },
  },
});
