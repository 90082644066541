
import { defineComponent } from "vue";
import MyNavigation from "@/components/Navigation.vue";
import MyFooter from "@/components/Footer.vue";
import { useCookie } from "vue-cookie-next";

export default defineComponent({
  name: "App",
  components: {
    MyNavigation,
    MyFooter,
  },
  setup() {
    const cookie = useCookie();
    cookie.setCookie("main", "null");
    cookie.removeCookie("hover-time");
  },
  mounted() {
    this.checkCookie();
  },
  data() {
    return {
      showConsent: true,
    };
  },
  methods: {
    checkCookie() {
      var consent = this.$cookie.getCookie("consent");
      if (consent == "null" || !consent) {
        this.showConsent = true;
      } else {
        this.showConsent = false;
      }

      console.log(consent, this.showConsent);
    },
    accept() {
      this.$cookie.setCookie("consent", false); //return this
      console.log(this.$cookie.getCookie("consent"));
      this.checkCookie();
    },
  },
});
