
import { defineComponent } from "vue";
import MyButton from "@/components/common-elements/Button.vue";
// import MyCountdown from "@/components/common-elements/Countdown.vue";
import MyTestimonial from "@/components/common-elements/Testimonial.vue";
import jsonData from "@/data/testimonials.json";

export default defineComponent({
  name: "MyHomePage",
  components: {
    MyButton,
    MyTestimonial,
  },
  methods: {
    shuffle(array: any) {
      let currentIndex = array.length,
        randomIndex;

      let newArray = [...array];

      // While there remain elements to shuffle.
      while (currentIndex != 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [newArray[currentIndex], newArray[randomIndex]] = [
          newArray[randomIndex],
          newArray[currentIndex],
        ];
      }

      return newArray;
    },
  },
  data() {
    return {
      btRetreats: {
        type: "route",
        value: "RetreatList",
        gtag: {
          name: "navigate_to",
          category: "goto",
          label: "click",
          value: "retreats",
        },
      },
      btUs: {
        type: "route",
        value: "KnowUs",
        gtag: {
          name: "navigate_to",
          category: "goto",
          label: "click",
          value: "knowus",
        },
      },
      btContact: {
        type: "url",
        value: "https://wa.me/351928022166",
        gtag: {
          name: "whatsapp",
          category: "whatsapp",
          label: "click",
          value: "homeWhatsApp",
        },
      },
      testemonialList: this.shuffle(jsonData),
    };
  },
});
